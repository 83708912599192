import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { SwipeContext } from '../contexts/SwipeProvider';
import { TokenMint } from '../common/types';
import { UserContext } from '../contexts/UserProvider';
import { SwapFromSolTx } from '../api/swipeApi';
import { useUnifiedWallet } from '@jup-ag/wallet-adapter';
import { Connection, VersionedTransaction } from '@solana/web3.js';
import { Buffer } from 'buffer';
import { rpcUrl } from '../config';
import SwipeableStack from '../components/SwipeableStack';
import PortfolioView from '../components/PortfolioView';
import useProxyWallet from '../hooks/proxyWallet';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { TxContext } from '../contexts/TxProvider';
import SwapModal from '../components/modals/SwapModal';

const drawerOpenPercent = '95%';
const drawerClosePercent = '13%';
const SwipePage = () => {
  const { swipeTokensStack, addTokenToHistory, addFailedMintToStack, addPendingTrade } =
    useContext(SwipeContext);
  const { addPendingAlert } = useContext(TxContext);
  const { defaultBuySolAmount, setTokenSearch, tokenSearch } = useContext(UserContext);
  const { publicKey, sendTransaction } = useProxyWallet();
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [drawerHeight, setDrawerHeight] = useState(drawerClosePercent);
  const startYRef = useRef(0);

  const buyToken = useCallback(
    async (token: TokenMint) => {
      if (!publicKey) {
        return alert('Missing pk');
      }
      const swapTxs = await SwapFromSolTx(
        'buy',
        publicKey.toBase58(),
        token.mint,
        defaultBuySolAmount,
        1,
        null
      );
      const txs = swapTxs.txs.map((txData: string) => {
        return VersionedTransaction.deserialize(Buffer.from(txData, 'base64'));
      });
      try {
        if (!swapTxs.txs) {
          throw new Error('No swap tx');
        }
        const txs = swapTxs.txs.map((txData: string) => {
          return VersionedTransaction.deserialize(Buffer.from(txData, 'base64'));
        });
        const signature = await sendTransaction(txs[0], new Connection(rpcUrl));
        addTokenToHistory(token);
        addPendingTrade(signature, token, swapTxs.quote ?? null);
      } catch (error: any) {
        console.log(JSON.stringify(error, null, 1));
        addPendingAlert({
          successText: '',
          pendingText: '',
          failedText: `Failed to send tx: ${error.message ? error.message : error.name}`,
          status: 'failed'
        });
        addTokenToHistory(token);
        setTimeout(() => {
          addFailedMintToStack(token.mint);
        }, 200);
      }
    },
    [
      addTokenToHistory,
      addPendingTrade,
      defaultBuySolAmount,
      publicKey,
      sendTransaction,
      addPendingAlert,
      addFailedMintToStack
    ]
  );

  useEffect(() => {
    if (initialLoad && swipeTokensStack.length > 0) {
      setInitialLoad(false);
    }
  }, [swipeTokensStack, initialLoad]);

  const handleTouchStart = (e: React.TouchEvent) => {
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const endY = e.touches[0].clientY;
    if (startYRef.current - endY > 50) {
      handleDrawerOpen();
      navigator.vibrate(50); // Haptic feedback for drawer open
    } else if (endY - startYRef.current > 50) {
      handleDrawerClose();
      navigator.vibrate(50); // Haptic feedback for drawer close
    }
  };

  const handleDrawerOpen = () => {
    setDrawerHeight(drawerOpenPercent);
  };

  const handleDrawerClose = () => {
    setDrawerHeight(drawerClosePercent);
  };

  if (tokenSearch) {
    return <SwapModal closeModalCallback={() => setTokenSearch(false)} />;
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow flex justify-center items-center">
        {swipeTokensStack.length !== 0 && (
          <SwipeableStack
            initial={initialLoad}
            cards={swipeTokensStack.slice().reverse()}
            swipeRight={(token) => {
              buyToken(token);
            }}
            swipeLeft={(token) => {
              setTimeout(() => {
                addTokenToHistory(token);
              }, 100);
            }}
          />
        )}
      </div>
      <Drawer
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        direction="bottom"
        size={drawerHeight}
        className="drawer-content"
        enableOverlay={false}
        lockBackgroundScroll={false}
        style={{ backgroundColor: 'black' }}>
        <div className="relative rounded-4xl border border-[#2c2e30] shadow-lg bg-[#0d1012] p-3">
          <div
            className="w-full h-12 absolute rounded block sm:hidden"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}></div>
          <div className="w-16 mx-auto h-1 bg-[#2c2e30] rounded block sm:hidden"></div>

          <div className="mx-auto h-1 w-full text-center hidden sm:block">
            {drawerHeight === drawerOpenPercent ? (
              <button onClick={handleDrawerClose}>
                <FaChevronDown className="text-white" />
              </button>
            ) : (
              <button onClick={handleDrawerOpen}>
                <FaChevronUp className="text-white" />
              </button>
            )}
          </div>

          <div className="mt-2 p-2 h-full overflow-y-auto">
            <PortfolioView showSlider={drawerHeight === drawerClosePercent} />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SwipePage;
