import React, { useState, useEffect, useRef } from 'react';
import { useSprings, animated, to as interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import Card, { tokenMintToTokenCard } from './Card';
import { TokenMint } from '../common/types';
import PrimaryButton from './ui/PrimaryButton';

const to = (i: number, topCardIndex: number) => ({
  x: 0,
  y: Math.min(i, 5) * -4,
  scale: 1,
  rot: i === topCardIndex ? 0 : (Math.random() - 0.5) * 15, // Ensure the top card is straight
  delay: Math.min(i, 5) * 100
});

const from = () => ({ x: 0, y: 0, scale: 1, rot: 0 });
const trans = (r: number, s: number) => `rotateZ(${r}deg) scale(${s})`;

interface SwipeableStackProps {
  swipeRight: (token: TokenMint) => void;
  swipeLeft: (token: TokenMint) => void;
  cards: TokenMint[];
  initial: boolean;
}

const SwipeableStack: React.FC<SwipeableStackProps> = ({
  cards,
  swipeRight,
  swipeLeft,
  initial
}) => {
  //TODO better handle useState with Set type
  const [gone] = useState(() => new Set<string>()); // Set to keep track of gone cards
  const [flipped, setFlipped] = useState(new Array(cards.length).fill(false)); // State to keep track of flipped cards
  const [props, set] = useSprings(cards.length, (i) => ({
    to: to(i, cards.length - 1),
    from: initial ? from() : to(i, cards.length - 1)
  })); // Create springs for each card

  //console.log(cards.length);

  const handleSwipe = (index: number, card: TokenMint, dir: number, autoMove: boolean = false) => {
    if (autoMove) {
      set((i) => {
        if (i !== index) return;
        const x = (200 + window.innerWidth) * dir; // When a card is gone it flies out left or right, otherwise goes back to zero
        const rot = 0; // How much the card tilts, flicking it harder makes it rotate faster
        const scale = 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: 200 }
        };
      });
    }

    gone.add(card.mint); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out

    // Remove from gone because mint can be shown again (if failed tx)
    setTimeout(() => {
      gone.delete(card.mint);
    }, 500);

    if (dir === 1) {
      swipeRight(card);
    } else if (dir === -1) {
      swipeLeft(card);
    }

    // set flipped to false
    setFlipped((prevFlipped) => {
      const newFlipped = [...prevFlipped];
      newFlipped[index] = false;
      return newFlipped;
    });
  };

  useEffect(() => {
    set((i) => {
      if (i === cards.length - 1) {
        return { rot: 0, config: { tension: 500, friction: 50 } };
      }
      return {};
    });
  }, [cards.length - 1, set]);

  const bind = useDrag((props) => {
    const {
      args: [index],
      down,
      movement: [mx],
      velocity,
      xy
    } = props;

    // Only allow dragging for the top card
    if (index !== cards.length - 1) return;

    let trigger = velocity > 0.01 && Math.abs(mx) > 50; // Add a threshold for movement distance
    const dir = mx < 0 ? -1 : 1; // Direction should either point left or right

    if (dir === 1 && xy[0] <= window.innerWidth / 2 + 50) {
      trigger = false;
    }
    if (dir === -1 && xy[0] > window.innerWidth / 2 - 50) {
      trigger = false;
    }

    if (!down && trigger) {
      handleSwipe(index, cards[index], dir);
    }

    set((i) => {
      if (index !== i) return; // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(cards[i].mint);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flies out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1; // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 }
      };
    });
  });

  const handleFlip = (index: number) => {
    if (index !== cards.length - 1) return;

    setFlipped((prevFlipped) => {
      const newFlipped = [...prevFlipped];
      newFlipped[index] = !newFlipped[index];
      return newFlipped;
    });
  };

  return (
    <div className="relative w-full flex flex-col items-center">
      <div className="flex-grow flex justify-center items-end pt-[25em]">
        {props.map(({ x, y, rot, scale }, i) => (
          <animated.div
            key={i}
            style={{
              transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
              position: 'absolute',
              width: '320px' // Ensure card width is consistent
            }}>
            <animated.div {...bind(i)} style={{ transform: interpolate([rot, scale], trans) }}>
              <Card
                token={tokenMintToTokenCard(cards[i])}
                flipped={flipped[i]}
                handleFlip={() => handleFlip(i)}
                isTopCard={i === cards.length - 1}
                swipePage={true}
              />
            </animated.div>
          </animated.div>
        ))}
        <div className="buttons-swipe-wrapper space-x-4 mt-8  hidden sm:flex">
          <PrimaryButton
            text="Fade 😶‍🌫"
            onClick={() => {
              handleSwipe(cards.length - 1, cards[cards.length - 1], -1, true);
            }}
            fullWidth={false}
          />
          <PrimaryButton
            text="Ape 🤑"
            onClick={() => {
              handleSwipe(cards.length - 1, cards[cards.length - 1], 1, true);
            }}
            fullWidth={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SwipeableStack;
