import { WalletProvider } from '@solana/wallet-adapter-react';
import React, { ReactNode, useCallback, useMemo } from 'react';

import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { UnifiedWalletProvider } from '@jup-ag/wallet-adapter';

export const WalletConnectionProvider = ({ children }: { children: ReactNode }) => {
  const network = WalletAdapterNetwork.Mainnet;

  const wallets = useMemo(() => [new PhantomWalletAdapter()], [network]);

  return (
    <UnifiedWalletProvider
      wallets={wallets}
      config={{
        autoConnect: false,
        env: 'mainnet-beta',
        theme: 'dark',
        metadata: {
          name: 'UnifiedWallet',
          description: 'UnifiedWallet',
          url: 'https://jup.ag',
          iconUrls: ['https://jup.ag/favicon.ico']
        }
      }}>
      <>{children}</>
    </UnifiedWalletProvider>
  );
};

export default WalletConnectionProvider;
