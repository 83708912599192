import React, {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo
} from 'react';

import { rpcUrl } from '../config';
import { UserContext } from './UserProvider';
import { SwipeContext } from './SwipeProvider';
import { mintToEmoji } from '../common/misc';
import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import { animated } from '@react-spring/web';
import { useSpring } from 'react-spring';

type AlertProps = {
  successText: string;
  pendingText: string;
  failedText: string;
  status: 'success' | 'failed';
};

type TxAlertProps = {
  signature: string;
  successText: string;
  pendingText: string;
  failedText: string;
};

type TxContextType = {
  addPendingTx: (props: TxAlertProps) => void;
  addPendingAlert: (props: AlertProps) => void;
  pendingAlerts: Map<string, PendingAlert>;
};

type PendingAlert = {
  signature: string;
  isTx: boolean;
  status: 'pending' | 'failed' | 'success';
  confirmedAt: number | null;
  sentAt: number;
  successText: string;
  pendingText: string;
  failedText: string;
};

export const TxContext = createContext<TxContextType>({
  addPendingTx: (props: TxAlertProps) => {},
  addPendingAlert: (props: AlertProps) => {},
  pendingAlerts: new Map()
});

export const TxProvider = ({ children }: { children: ReactNode }) => {
  const [fadeIn, setFadeIn] = useSpring(() => ({
    opacity: 1
  }));

  const { refetchBalance } = useContext(UserContext);

  const { pendingTrades } = useContext(SwipeContext);
  const [pendingTxs, setPendingTxs] = useState(new Map<string, PendingAlert>());

  const [tradesStackShown, setTradesStackShown] = useState(new Set<string>());
  const [tradesStackProcessed, setTradesStackProcessed] = useState(new Set<string>());

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const signatures = [...pendingTxs.entries()]
        .filter(([key, value]) => value.status === 'pending' && value.isTx)
        .map(([key, value]) => key);

      if (signatures.length > 0) {
        const result = await fetch(rpcUrl, {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getSignatureStatuses',
            params: [
              signatures,
              {
                searchTransactionHistory: true
              }
            ]
          })
        });
        const data = await result.json();

        for (let i = 0; i < signatures.length; i++) {
          const signature = signatures[i];
          const result = data?.result?.value[i];
          const pending = pendingTxs.get(signature);
          if (!pending) continue;

          if (pending.status === 'pending') {
            if (result) {
              const status =
                result.err === null && result.status.Ok !== undefined ? 'success' : 'failed';

              if (status === 'success') {
                refetchBalance();
              }

              setPendingTxs(
                (map) =>
                  new Map(
                    map.set(signature, {
                      ...pending,
                      confirmedAt: Date.now(),
                      status
                    })
                  )
              );
            }

            if (!result && pending.sentAt < Date.now() - 1000 * 60) {
              setPendingTxs(
                (map) =>
                  new Map(
                    map.set(signature, {
                      ...pending,
                      confirmedAt: Date.now(),
                      status: 'failed'
                    })
                  )
              );
            }
          }
        }
      }

      for (const [signature, pending] of pendingTxs) {
        if (pending.confirmedAt && pending.confirmedAt < Date.now() - 1000 * 5) {
          setPendingTxs((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.delete(signature);
            return newMap;
          });
        }
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [pendingTxs, setPendingTxs, refetchBalance]);

  const addPendingTx = useCallback(
    (props: TxAlertProps) => {
      setPendingTxs(
        (map) =>
          new Map(
            map.set(props.signature, {
              isTx: true,
              status: 'pending',
              confirmedAt: null,
              sentAt: Date.now(),
              ...props
            })
          )
      );
    },
    [setPendingTxs]
  );

  const addPendingAlert = useCallback(
    (props: AlertProps) => {
      const uniqueId = `alert-${Date.now()}-${Math.random()}`;
      setPendingTxs(
        (map) =>
          new Map(
            map.set(uniqueId, {
              isTx: false,
              signature: uniqueId,
              confirmedAt: Date.now(),
              sentAt: Date.now(),
              ...props
            })
          )
      );
    },
    [setPendingTxs]
  );

  const pendingTradesView = useMemo(() => {
    const tradesPending = [...pendingTrades.values()].filter((pt) => pt.status === 'pending');

    const tradesCompleted = [...pendingTrades.values()]
      .filter((pt) => pt.status !== 'pending' && !tradesStackProcessed.has(pt.signature))
      .sort((pt1, pt2) => (pt1.confirmedAt ?? 0) - (pt2.confirmedAt ?? 0));

    if (tradesCompleted.length) {
      const pt = tradesCompleted[0];

      if (!tradesStackShown.has(pt.signature)) {
        setTradesStackShown((old: Set<string>) => new Set([...old, pt.signature]));
        setFadeIn({ opacity: 1, from: { opacity: 0 } });
        setTimeout(() => {
          setTradesStackProcessed((old: Set<string>) => new Set([...old, pt.signature]));
          setFadeIn({ opacity: 1, from: { opacity: 0 } });
        }, 3000);
      }

      const boughtAmount =
        pt.quote && pt.quote.outAmount
          ? BigNumber(pt.quote.outAmount).div(10 ** pt.quote.tokenDecimals)
          : null;

      return (
        <animated.div style={fadeIn}>
          {pt.status === 'success' && (
            <div className="text-[#9BEC7F] glow-text-green font-bold text-xl">{`Bought${boughtAmount ? ' ' + numeral(boughtAmount.toFixed(2)).format('0.00a') : ''} ${pt.token.symbol}`}</div>
          )}
          {pt.status === 'failed' && (
            <div className="text-[#FF0007] glow-text-red font-bold text-xl">{`TX failed 🥺 ${pt.token.symbol} is the next card`}</div>
          )}
        </animated.div>
      );
    } else if (tradesPending.length) {
      return (
        <animated.div style={fadeIn}>
          {tradesPending.slice(0, 5).map((pt, i) => {
            return (
              <span className="text-4xl" key={`mint-emoji-${i}`}>
                {mintToEmoji(pt.token.mint)}
              </span>
            );
          })}
        </animated.div>
      );
    }

    return <></>;
  }, [
    setFadeIn,
    pendingTrades,
    tradesStackProcessed,
    tradesStackShown,
    setTradesStackProcessed,
    setTradesStackShown
  ]);

  return (
    <TxContext.Provider
      value={{
        pendingAlerts: pendingTxs,
        addPendingTx,
        addPendingAlert
      }}>
      {children}

      <div style={{ zIndex: 201 }} className="bottom-24 absolute alert-center">
        {pendingTradesView}
      </div>

      <div style={{ zIndex: 200 }}>
        {Array.from(pendingTxs.entries()).map((entry) => {
          const [signature, pendingTx] = entry;
          if (pendingTx.status !== 'pending') return null;

          return (
            <div
              key={signature}
              className="absolute w-full bottom-6"
              style={{
                zIndex: Math.abs(Date.now() - (pendingTx.confirmedAt ?? 0))
              }}>
              <div
                className="text-center mx-auto max-w-xs text-sm text-white rounded-xl shadow-lg"
                role="alert">
                <animated.div style={fadeIn}>
                  <div className="text-[#f3ff46] glow-text-custom-yellow font-bold text-xl">
                    {pendingTx.pendingText}
                  </div>
                </animated.div>
              </div>
            </div>
          );
        })}

        {Array.from(pendingTxs.entries()).map((entry) => {
          const [signature, pendingTx] = entry;
          if (pendingTx.status === 'pending') return null;

          return (
            <div
              key={signature}
              className="absolute w-full bottom-6"
              style={{
                zIndex: Math.abs(Date.now() - (pendingTx.confirmedAt ?? 0))
              }}>
              <div
                className="text-center mx-auto max-w-xs text-sm text-white rounded-xl shadow-lg"
                role="alert">
                <animated.div style={fadeIn}>
                  {pendingTx.status === 'success' && (
                    <div className="text-[#9BEC7F] glow-text-green font-bold text-xl">
                      {pendingTx.successText}
                    </div>
                  )}
                  {pendingTx.status === 'failed' && (
                    <div className="text-[#FF0007] glow-text-red font-bold text-xl">
                      {pendingTx.failedText}
                    </div>
                  )}
                </animated.div>
              </div>
            </div>
          );
        })}
      </div>
    </TxContext.Provider>
  );
};

export default TxProvider;
