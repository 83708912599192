import React from 'react';

interface PrimaryButtonProps {
  text?: string;
  callback?: () => void;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  callback,
  fullWidth,
  onClick,
  children
}) => {
  const buttonStyle = 'btn-primary' + ' ' + (fullWidth ? 'w-full' : 'w-32 max-w-64');

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // Vibrate for 50ms
    }
    if (onClick) {
      onClick(e);
    } else if (callback) {
      callback();
    }
  };

  return (
    <button className={buttonStyle} onClick={handleClick} style={{ height: '50px' }}>
      {children || text}
    </button>
  );
};

export default PrimaryButton;
