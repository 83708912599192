import React from 'react';

import DefaultBuyAmountModal from '../components/modals/DefaultBuyAmountModal';
import { useNavigate } from 'react-router-dom';
const SettingsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-5 w-full">
      <DefaultBuyAmountModal
        onButtonCallback={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};

export default SettingsPage;
