import React, { ReactElement } from 'react';
import { IoMdClose } from 'react-icons/io';

export default (props: {
  emoji: ReactElement;
  title: ReactElement;
  subtitle: ReactElement;
  content: ReactElement;
  closeModalCallback?: () => void;
}) => {
  return (
    <div className="flex h-screen">
      <div className="m-auto w-full">
        {props.closeModalCallback && (
          <div className="relative -top-12 md:w-1/2 sm:w-full ">
            <button
              className="absolute text-4xl opacity-50 hover:opacity-100"
              onClick={() => {
                if (props.closeModalCallback) {
                  props.closeModalCallback();
                }
              }}>
              <IoMdClose />
            </button>
          </div>
        )}

        <div className="flex flex-col justify-center items-center">
          <div className="text-center mb-8">
            <span role="img" aria-label="money with wings" className="text-4xl">
              {props.emoji}
            </span>
            <h2 className="mb-2">{props.title}</h2>
            <p className="secondary">{props.subtitle}</p>
          </div>
          {props.content}
        </div>
      </div>
    </div>
  );
};
