import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useNavigate } from 'react-router-dom';

const PortfolioTokens = () => {
  const { tokens } = useContext(UserContext);

  const navigate = useNavigate();

  const setDisplayNone = (e: any) => {
    e.target.style.display = 'none';
  };

  if (tokens.length === 0) {
    return (
      <>
        <div className="flex flex-col text-center h-full align-center mt-12">
          <div className="flex-grow h-full">
            <div style={{ fontSize: '72px' }}>🪤</div>
            <div className="text-2xl">No swipe cards</div>
            <div className="text-sm opacity-40">Find new memecoins in the swipe section</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-2 overflow-auto max-h-[60dvh]">
      {tokens.map((token) => {
        let tokenValue: BigNumber | null = null;
        let pnl: BigNumber | null = null;
        if (token.userToken !== null && token.market !== null && token.market.price !== null) {
          tokenValue = BigNumber(token.userToken?.balanceDecimal).multipliedBy(token.market.price);

          if (token?.pnl) {
            pnl = tokenValue.div(token.pnl.tokenValueUsd).minus(1).multipliedBy(100);
          }
        }

        let image = token.imageUrl;
        if (image?.startsWith('https://cf-ipfs.com/ipfs/')) {
          image = image?.replace('https://cf-ipfs.com/ipfs/', 'https://pump.mypinata.cloud/ipfs/');
        }

        return (
          <div
            key={token.mint}
            className=""
            onClick={() => {
              navigate(`/tokens/${token.mint}`);
            }}>
            <div className="token-frame mx-1 my-1">
              <div className="relative group rounded-2xl min-h-40 overflow-hidden">
                <img
                  className="token-img object-cover w-full transform transition-transform duration-500 group-hover:scale-105 hover:cursor-pointer"
                  src={image ?? ''}
                  onError={setDisplayNone}
                  draggable="false"
                />
                <div className="absolute bottom-0 h-8 bg-gradient-to-t from-black to-transparent w-full"></div>
                <div className="absolute bottom-1 left-1 p-1 text-lg font-bold">
                  ${token.symbol}
                </div>
              </div>

              <div className="flex justify-between text-xs font-thin">
                {tokenValue && (
                  <div>
                    💰$
                    {numeral(tokenValue.toFixed(2)).format('0.00a')}
                  </div>
                )}
                {pnl !== null && (
                  <div>
                    {pnl.isPositive() ? '☝️' : '👇'}{' '}
                    {pnl.isPositive() ? (
                      <span className="text-green-500">
                        {numeral(pnl.toFixed(2)).format('0.00a')}%
                      </span>
                    ) : (
                      <span className="text-red-500">
                        {numeral(pnl.toFixed(2)).format('0.00a')}%
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PortfolioTokens;
