import React, { useCallback, useContext, useState } from 'react';

import { UserContext } from '../../contexts/UserProvider';
import BigNumber from 'bignumber.js';
import { Connection, LAMPORTS_PER_SOL, VersionedTransaction } from '@solana/web3.js';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { apiUrl, rpcUrl } from '../../config';
import { TokenMint } from '../../common/types';
import { TxContext } from '../../contexts/TxProvider';
import { SOL_MINT, WSOL_MINT } from '../../common/constants';
import useProxyWallet from '../../hooks/proxyWallet';
import { SwapFromSolTx } from '../../api/swipeApi';
import Modal from './Modal';
import SliderCard from '../SliderCard';
import { Buffer } from 'buffer';

export default (props: {
  onButtonCallback: () => void;
  mint: string;
  closeModalCallback?: () => void;
}) => {
  const { defaultBuySolAmount } = useContext(UserContext);
  const { tokens } = useContext(UserContext);
  const { publicKey, sendTransaction } = useProxyWallet();
  const { addPendingTx, addPendingAlert } = useContext(TxContext);

  const solToken = tokens.find((t) => t.mint === SOL_MINT) as TokenMint;
  const token = tokens.find((t) => t.mint === props.mint) as TokenMint;

  const [amount, setAmount] = useState(BigNumber(0.0001));

  const debouncedAmount = useDebounce(amount, 1000);

  const { data: buyData } = useQuery({
    queryKey: ['priceQuote', ...[token, debouncedAmount]],
    queryFn: () => {
      return fetch(`${apiUrl}/v1/swap/quote`, {
        method: 'POST',
        body: JSON.stringify({
          inMint: WSOL_MINT,
          outMint: token.mint,
          inAmount: debouncedAmount.integerValue().toString(),
          slippage: 5
        })
      })
        .then((res) => res.json())
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    staleTime: 1000
  });

  const buyToken = useCallback(async () => {
    if (!publicKey) {
      return alert('Missing pk');
    }

    const swapTxs = await SwapFromSolTx(
      'buy',
      publicKey.toBase58(),
      token.mint,
      debouncedAmount,
      1,
      buyData?.quoteResponse
    );

    const txs = swapTxs.txs.map((txData: string) => {
      return VersionedTransaction.deserialize(Buffer.from(txData, 'base64'));
    });

    try {
      const signature = await sendTransaction(txs[0], new Connection(rpcUrl));

      console.log('signature', signature);
      addPendingTx({
        signature,
        successText: `Success buying ${token.symbol}`,
        pendingText: `Pending buying ${token.symbol}`,
        failedText: `Failed buying ${token.symbol}`
      });
    } catch (error: any) {
      addPendingAlert({
        successText: '',
        pendingText: '',
        failedText: `Failed to send tx: ${error.message ? error.message : error.name}`,
        status: 'failed'
      });
    }
  }, [publicKey, debouncedAmount, addPendingTx, buyData]);

  const updateAmount = useCallback(
    (_amount: BigNumber) => {
      setAmount(_amount);
    },
    [setAmount]
  );

  if (solToken === undefined || token === undefined) {
    return (
      <div className="flex h-screen p-5">
        <div className="m-auto">Loading</div>
      </div>
    );
  }

  return (
    <Modal
      closeModalCallback={props.closeModalCallback}
      emoji={<>🦍</>}
      title={<>Buy in {token.symbol}</>}
      subtitle={<>Set the amount of {token.symbol} you want to buy</>}
      content={
        <SliderCard
          priceUsd={solToken.market?.price ? Number(solToken.market?.price) : undefined}
          inputBalance={BigNumber(solToken?.userToken?.balance ?? 0)}
          inputAmountMax={BigNumber(solToken?.userToken?.balance ?? 0)}
          inputAmount={amount}
          inputAmountMin={BigNumber(0.0001 * LAMPORTS_PER_SOL)}
          inputDecimals={9}
          inputSymbol={'SOL'}
          inputImage={null}
          outputLoading={!amount.eq(debouncedAmount)}
          outputAmount={BigNumber(buyData?.outAmount ?? 0)}
          outputDecimals={token.decimals}
          outputSymbol={token.symbol}
          onAmountChange={updateAmount}
          buttonText={'Buy'}
          onButtonCallback={(_amount) => {
            buyToken();
          }}
        />
      }
    />
  );
};
