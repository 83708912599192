import React, { useCallback, useContext, useEffect, useState } from 'react';
import PrimaryButton from '../components/ui/PrimaryButton';
import { useUnifiedWalletContext } from '@jup-ag/wallet-adapter';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import useProxyWallet from '../hooks/proxyWallet';

const validPasscodes = ['toomuchsugar', 'solmaxi', 'coinjunky', 'singapoor', 'brokepoint'];
const LandingPage = () => {
  const { defaultBuySolAmount } = useContext(UserContext);
  const { setShowModal } = useUnifiedWalletContext();
  const { connected } = useProxyWallet();
  const navigate = useNavigate();

  const [passcode, setPasscode] = useState('');
  const [inLocalStorage, setInLocalStorage] = useState<null | string>(null);
  const [showWrongPass, setShowWrongPass] = useState(false);

  useEffect(() => {
    const _passcode = localStorage.getItem('_p');
    if (_passcode && validPasscodes.includes(_passcode)) {
      setInLocalStorage(_passcode);
      setPasscode(_passcode);

      if (connected) {
        if (defaultBuySolAmount.isZero()) {
          navigate('/onboarding');
        } else {
          navigate('/main');
        }
      }
    }
  }, [navigate, connected]);

  const checkPasscode = useCallback(() => {
    if (validPasscodes.includes(passcode) || inLocalStorage) {
      if (passcode) {
        localStorage.setItem('_p', passcode);
      }
      setShowModal(true);
    }
    if (!validPasscodes.includes(passcode)) {
      setShowWrongPass(true);
      setPasscode('');
      setTimeout(() => {
        setShowWrongPass(false);
      }, 1500);
    }
  }, [passcode]);

  return (
    <div className="landing-page">
      <div className="landing-coins-wrapper fixed z-1  md:w-1/2 md:top-12 md:left-1/4 sm:w-full sm:h-full sm:top-0 sm:left-0">
        <div className="absolute top-12 left-3 w-24 h-24 animate-float1">
          <img src="/landing/image4.png" alt="bg1" className="rounded-full" />
        </div>

        <div className="absolute top-24 right-10 w-24 h-24 animate-float3">
          <img src="/landing/image5.png" alt="bg2" className="rounded-full" />
        </div>

        <div className="absolute top-1/2 -left-5 w-24 h-24 animate-float3">
          <img src="/landing/image6.png" alt="bg1" className="rounded-full" />
        </div>

        <div className="absolute top-1/2 -right-10 w-24 h-24 animate-float2">
          <img src="/landing/image7.png" alt="bg2" className="rounded-full" />
        </div>

        <div className="absolute bottom-24 left-12 w-24 h-24  animate-float1">
          <img src="/landing/image2.png" alt="bg3" className="rounded-full " />
        </div>

        <div className="absolute bottom-32 right-10 w-24 h-24 animate-float4">
          <img src="/landing/image3.png" alt="bg6" className="rounded-full  " />
        </div>
      </div>

      <div className="flex h-screen">
        <div className="relative z-2 text-center my-auto">
          <main className="getting-started p-12 rounded-lg shadow-lg">
            <div className="relative">
              <img
                src="/landing/logo-new.png"
                alt="Swipe.fun Logo"
                className="matcher-img mx-auto mb-4"
              />
              <div className="absolute shadow-md shadow-black bottom-2 right-14 bg-primary-color text-black rounded-full font-bold text-lg py-1 px-3 -rotate-12">
                Beta
              </div>
            </div>
            <h1 className="mb-2 font-bold">
              swipe<span className="text-primary-color">.</span>fun
            </h1>
            <p className="opacity-40 mb-6">Fun and safe way to trade memecoins</p>

            {!inLocalStorage && (
              <div className="relative mb-4">
                <input
                  type="text"
                  placeholder=""
                  className="w-full p-2 pl-10  rounded-2xl bg-[#090B0C] text-white  border-[#2c2e30] border"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                />
                <span className="absolute left-3 top-2.5 text-gray-400">🔑</span>
                {showWrongPass && (
                  <div className="text-5xl w-full -m-10 absolute z-10 mx-auto">🙅‍♀️</div>
                )}
              </div>
            )}

            <PrimaryButton text="Get started" callback={() => checkPasscode()} fullWidth={true} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
