import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import numeral from 'numeral';
import MiniChart from './MiniChart';
import { OhlcvResponse, TokenMint } from '../common/types';
import Threads from './Threads';
import Autoscroller from './Autoscroller';
import { apiUrl } from '../config';
import BigNumber from 'bignumber.js';
import { formatDistance } from 'date-fns';

type CardToken = {
  mint: string;
  name: string;
  subname?: string;
  image: string;
  description: string;
  percentage: string;
  platform: 'pumpfun' | 'moonshot' | 'jupiter' | null;
  percentageType: 'positive' | 'negative';
  tags: {
    emoji?: string;
    text: string;
    link?: string;
  }[];
  price: BigNumber | null;
};

interface CardProps {
  token: CardToken;
  flipped: boolean;
  handleFlip: () => void;
  isTopCard: boolean;
  swipePage: boolean;
}

type PercentageType = {
  text: string;
  type: 'positive' | 'negative';
};

export function tokenMintToTokenCard(tokenMint: TokenMint): CardToken {
  const tags = [];

  let marketCapString: string | null = null;

  if (
    tokenMint.market?.marketCap &&
    !Number.isNaN(Number(tokenMint.market?.marketCap)) &&
    Number(tokenMint.market?.marketCap) > 0
  ) {
    marketCapString = `$${numeral(Number(tokenMint.market?.marketCap).toFixed(2)).format('0.00a')}`;
    // tags.push({
    //   emoji: '🧢',
    //   text: marketCapString
    // });
  }

  if (
    tokenMint.market?.volume24h &&
    !Number.isNaN(Number(tokenMint.market?.volume24h)) &&
    Number(tokenMint.market?.volume24h) > 0
  ) {
    tags.push({
      emoji: '🔊',
      text: `$${numeral(Number(tokenMint.market?.volume24h).toFixed(2)).format('0.00a')}`
    });
  }

  tags.push({
    emoji: tokenMint.swappable === 'pumpfun' ? '💊↪️' : '🌙↪️',
    text: `${Number(tokenMint?.pumpfun?.bondingCurvePercentage ?? tokenMint?.moonshot?.bondingCurvePercentage ?? 0).toFixed(0)}%`
  });

  if (tokenMint.firstMintAt) {
    let formatedTime = formatDistance(new Date(tokenMint.firstMintAt), new Date(), {
      addSuffix: true
    });

    if (formatedTime.startsWith('about')) {
      formatedTime = formatedTime.replace('about ', '');
    }
    formatedTime = formatedTime.charAt(0).toUpperCase() + formatedTime.slice(1);

    tags.push({
      emoji: '⌛️',
      text: formatedTime
      //=> "3 days ago"
    });
  }

  if (Number.isInteger(tokenMint.pumpfun?.replies)) {
    tags.push({
      emoji: '💬',
      text: `${tokenMint?.pumpfun?.replies} comments`
    });
  }

  let image = tokenMint.imageUrl;

  if (image?.startsWith('https://cf-ipfs.com/ipfs/')) {
    image = image?.replace('https://cf-ipfs.com/ipfs/', 'https://pump.mypinata.cloud/ipfs/');
  }

  return {
    price: BigNumber(tokenMint.market?.price ?? 0),
    mint: tokenMint.mint,
    name: tokenMint.name,
    subname: tokenMint.symbol,
    platform: tokenMint.swappable,
    percentage: marketCapString,
    percentageType: 'positive',
    image,
    description: tokenMint.description,
    tags
  } as CardToken;
}

const Card: React.FC<CardProps> = ({ swipePage, token, flipped, handleFlip, isTopCard }) => {
  const [chartData, setChartData] = useState<OhlcvResponse | null>(null);
  const tagsWrapperRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<HTMLDivElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [scrollDuration, setScrollDuration] = useState('0s');
  const [scrollDistance, setScrollDistance] = useState('0px');
  const [lastTap, setLastTap] = useState<number | null>(null);

  const backRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTopCard) {
      const calculateScroll = () => {
        if (tagsWrapperRef.current && tagsRef.current) {
          const tagsWidth = tagsRef.current.scrollWidth;
          const wrapperWidth = tagsWrapperRef.current.clientWidth;
          if (tagsWidth > wrapperWidth) {
            setShouldAnimate(true);
            setScrollDistance(`${tagsWidth - wrapperWidth}px`);
            setScrollDuration(`${(tagsWidth - wrapperWidth) / 10}s`); // Slower animation
          } else {
            setShouldAnimate(false);
            setScrollDistance('0px');
            setScrollDuration('0s');
          }
        }
      };

      calculateScroll();

      // Recalculate on window resize
      window.addEventListener('resize', calculateScroll);
      return () => window.removeEventListener('resize', calculateScroll);
    }
  }, [token.tags, isTopCard]);

  const bind = useDrag(
    ({ movement: [mx], memo = 0 }) => {
      if (tagsWrapperRef.current && tagsRef.current) {
        const tagsWidth = tagsRef.current.scrollWidth;
        const wrapperWidth = tagsWrapperRef.current.clientWidth;
        const maxScroll = tagsWidth - wrapperWidth;

        let x = memo + mx;
        if (x > 0) x = 0;
        if (x < -maxScroll) x = -maxScroll;

        tagsRef.current.style.transform = `translateX(${x}px)`;
        return x;
      }
      return memo;
    },
    { axis: 'x' }
  );

  const handleDoubleTap = () => {
    const now = Date.now();
    if (lastTap && now - lastTap < 300) {
      handleFlip();
    } else {
      setLastTap(now);
    }
  };

  useEffect(() => {
    if (!isTopCard) {
      return;
    }

    if (swipePage && chartData === null && token.platform === 'pumpfun') {
      try {
        fetch(`${apiUrl}/v1/tokens/${token.mint}/chart`)
          .then((response) => response.json())
          .then(({ data }) => {
            setChartData(data);
          });
      } catch (e) {
        return;
      }
    }
    return;
  }, [token, isTopCard]);

  return (
    <div
      className="card-container card-width relative"
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
      onClick={handleDoubleTap}>
      <div className={`card prevent-select ${flipped ? 'flipped' : ''}`}>
        <div className="absolute w-full h-full backface-hidden">
          <div className="relative w-full h-full flex flex-col p-2 text-white rounded-4xl border border-[#2c2e30] shadow-lg bg-[#0d1012]">
            {/* Front Side */}
            <div className="p-3 flex flex-col">
              <div className="mb-2 flex items-center justify-between h-4">
                <div className="flex items-center gap-x-2">
                  <h2
                    className={`${token.name.length > 12 ? 'font-lg' : 'font-2xl'} font-medium capitalize max-w-32 overflow-hidden text-ellipsis text-nowrap`}>
                    {token.name}
                  </h2>
                  {token.subname && (
                    <span className="font-xs text-white opacity-40 max-w-24 overflow-hidden text-ellipsis">
                      {token.subname}
                    </span>
                  )}
                </div>
                {token.percentage !== null && (
                  <div className="text-right w-32 block text-xl font-sf-bold overflow-hidden">
                    {token.percentage}
                  </div>
                )}
              </div>
              <div className="relative">
                <img
                  className="w-full token-image object-cover mt-2 rounded-2xl"
                  src={token.image ?? 'https://via.placeholder.com/300'}
                  alt={token.name}
                  draggable={false}
                />
                {swipePage && token.platform === 'pumpfun' && (
                  <div className="top-token px-3 bg-[##09D37E]">
                    <span className="text-xs mr-1">Pump.fun</span>
                    <span className="text-sm">💊</span>
                  </div>
                )}
                {swipePage && token.platform === 'moonshot' && (
                  <div className="top-token px-3 bg-gray-900">
                    <span className="text-xs mr-1">Moonshot</span>
                    <span className="text-sm">🌙</span>
                  </div>
                )}
              </div>

              <div className="relative my-4 p-4 bg-[#090b0c] rounded-xl">
                <p className="text-gray-400">Description:</p>
                <div className="description">
                  <Autoscroller speed={20}>
                    <p className="text-white-400">{token.description}</p>
                  </Autoscroller>
                </div>
              </div>

              {isTopCard && (
                <div
                  ref={tagsWrapperRef}
                  className="flex p-2 rounded-b-xl tags-wrapper"
                  style={
                    isTopCard
                      ? ({
                          '--scroll-distance': scrollDistance,
                          '--scroll-duration': scrollDuration
                        } as React.CSSProperties)
                      : {}
                  }>
                  <div
                    ref={tagsRef}
                    className={`tags flex space-x-2 ${shouldAnimate && isTopCard ? 'animate' : ''}`}
                    {...bind()}>
                    {token.tags.map((tag, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-2 bg-[#171a1b] px-3 py-1 rounded-full">
                        {tag.emoji !== undefined && <span className="mr-0.5">{tag.emoji}</span>}{' '}
                        {tag.link !== undefined && (
                          <a href={tag.link} target="_blank" rel="noreferrer">
                            {tag.text}
                          </a>
                        )}
                        {tag.link === undefined && <span>{tag.text}</span>}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Back Side */}
        <div
          ref={backRef}
          className="absolute overflow-hidden w-full h-full backface-hidden rotate-y-180 bg-[#0d1012] rounded-4xl border border-[#2c2e30] flex flex-col items-center">
          <div className="flex flex-col card-width">
            <div className="pt-5 px-5 mb-2 flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <h2
                  className={`${token.name.length > 12 ? 'font-lg' : 'font-2xl'} font-medium capitalize max-w-32 overflow-hidden text-ellipsis text-nowrap`}>
                  {token.name}
                </h2>
                {token.subname && (
                  <span className="font-xs text-white opacity-40 max-w-24 overflow-hidden text-ellipsis">
                    {token.subname}
                  </span>
                )}
              </div>
              {token.percentage !== null && (
                <div className="text-right w-32 block text-xl font-sf-bold overflow-hidden">
                  {token.percentage}
                </div>
              )}
            </div>
            <MiniChart chartData={chartData} visible={flipped} />

            <Threads mint={token.mint} visible={flipped} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
