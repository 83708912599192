import React, { useContext, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import PrimaryButton from '../components/ui/PrimaryButton';
import BuyTokenModal from '../components/modals/BuyTokenModal';
import SellTokenModal from '../components/modals/SellTokenModal';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import numeral from 'numeral';
import { SOL_MINT } from '../common/constants';
import Card, { tokenMintToTokenCard } from '../components/Card';

const TokenPage = () => {
  const { tokens } = useContext(UserContext);
  const navigate = useNavigate();
  const { mint } = useParams();

  const [buyMint, setBuyMint] = useState<string | null>(null);
  const [sellMint, setSellMint] = useState<string | null>(null);
  const [flipped, setFlipped] = useState(false);

  const token = tokens.find((t) => t.mint === mint);

  if (!token) {
    return <Navigate to={'/main'} />;
  }

  if (buyMint) {
    return (
      <div className="p-5 w-full">
        <BuyTokenModal
          mint={buyMint}
          onButtonCallback={() => {}}
          closeModalCallback={() => setBuyMint(null)}
        />
      </div>
    );
  }

  if (sellMint) {
    return (
      <div className="p-5 w-full">
        <SellTokenModal
          mint={sellMint}
          onButtonCallback={() => {}}
          closeModalCallback={() => setSellMint(null)}
        />
      </div>
    );
  }

  return (
    <div className="flex h-screen px-5">
      <br />
      <div className="m-auto">
        <div className="relative mb-4 md:w-1/2 sm:w-full">
          <button
            onClick={() => navigate(-1)}
            className="text-2xl py-2 opacity-50 hover:opacity-100">
            <IoIosArrowBack />
          </button>
        </div>

        <div className="flex flex-col justify-around h-full pb-4">
          <Card
            token={tokenMintToTokenCard(token)}
            flipped={flipped}
            handleFlip={() => setFlipped(!flipped)}
            isTopCard={true}
            swipePage={false}
          />

          {token.mint !== SOL_MINT && (
            <div className="buttons-single-token-page">
              <PrimaryButton
                onClick={() => {
                  if (token?.userToken !== null) {
                    setSellMint(token.mint);
                  }
                }}
                fullWidth={true}>
                {token?.userToken !== null ? 'Sell' : <span className="line-through">Sell</span>}
              </PrimaryButton>
              <PrimaryButton text="Buy" onClick={() => setBuyMint(token.mint)} fullWidth={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenPage;
