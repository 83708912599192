import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { useUnifiedWallet } from '@jup-ag/wallet-adapter';
import useProxyWallet from '../hooks/proxyWallet';
export const PrivateRoutes = () => {
  const { connected } = useProxyWallet();

  return connected ? <Outlet /> : <Navigate to="/" />;
};
