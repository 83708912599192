import BigNumber from 'bignumber.js';
import { TokenMint } from '../common/types';
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUrl } from '../config';
import useProxyWallet from '../hooks/proxyWallet';

type UserContextType = {
  tokenSearch: boolean;
  setTokenSearch: (set: boolean) => void;
  defaultBuySolAmount: BigNumber;
  updateDefaultSolAmount: (amount: BigNumber) => void;
  refetchBalance: () => void;
  tokensFetched: boolean;
  tokens: TokenMint[];
  addToken: (token: TokenMint) => void;
  portfolioValueUsd: {
    total: string | null;
    change: string | null;
    changePercentage: string | null;
  } | null;
};

export const UserContext = createContext<UserContextType>({
  defaultBuySolAmount: BigNumber(0),
  refetchBalance: () => {},
  updateDefaultSolAmount: (amount) => {},
  tokens: [],
  tokensFetched: false,
  portfolioValueUsd: null,
  setTokenSearch: (set) => {},
  addToken: (token) => {},
  tokenSearch: false
});

const lsKey_defaultSolAmount = 'set_defaultSolAmount';
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [tokenSearch, setTokenSearch] = useState(false);
  const [addedTokens, setAddedTokens] = useState<TokenMint[]>([]);

  const { publicKey } = useProxyWallet();

  const [tokensFetched, setTokensFetched] = useState(false);

  const _defAmount =
    localStorage.getItem(lsKey_defaultSolAmount) !== null
      ? BigNumber(localStorage.getItem(lsKey_defaultSolAmount) as string)
      : BigNumber(0);
  const [defaultBuySolAmount, setDefaultSolAmount] = useState(_defAmount);

  useEffect(() => {
    localStorage.setItem(lsKey_defaultSolAmount, defaultBuySolAmount.toString());
  }, [defaultBuySolAmount]);

  const updateDefaultSolAmount = useCallback(
    (amount: BigNumber) => {
      if (amount.gt(0)) {
        setDefaultSolAmount(amount);
      }
    },
    [setDefaultSolAmount]
  );

  const queryClient = useQueryClient();

  const addToken = useCallback(
    (token: TokenMint) => {
      setAddedTokens((prevStateArray) => [...prevStateArray, token]);
    },
    [setAddedTokens]
  );
  const refetchBalance = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['userTokens'] });
  }, []);

  const { data: tokensData } = useQuery({
    queryKey: ['userTokens'],
    queryFn: () =>
      fetch(`${apiUrl}/v1/sol/tokens?address=${publicKey?.toBase58()}`).then((res) => res.json()),
    enabled: publicKey !== null,
    refetchInterval: 10000
  });

  if (tokensData?.data?.tokens && !tokensFetched) {
    setTokensFetched(true);
  }

  return (
    <UserContext.Provider
      value={{
        addToken,
        setTokenSearch,
        tokenSearch,
        tokensFetched,
        refetchBalance,
        defaultBuySolAmount,
        updateDefaultSolAmount,
        tokens: tokensData?.data?.tokens ? [...tokensData?.data?.tokens, ...addedTokens] : [],
        portfolioValueUsd: tokensData?.data?.portfolioValueUsd ?? null
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
