import React, { ReactNode, createContext, useEffect, useState, useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { apiUrl, rpcUrl } from '../config';
import { TradableQuote, TokenMint } from '../common/types';
import useProxyWallet from '../hooks/proxyWallet';

type SwipeContextType = {
  addPendingTrade: (signature: string, token: TokenMint, quote: TradableQuote | null) => void;
  addFailedMintToStack: (mint: string) => void;
  addTokenToHistory: (token: TokenMint) => void;
  swipeTokensStack: TokenMint[];
  pendingTrades: Map<string, PendingTrade>;
  // failedMintsStack: string[];
  // historyTokensMap: Map<string, TradableToken>;
};

const STACK_SIZE = 10;

type PendingTrade = {
  signature: string;
  status: 'pending' | 'failed' | 'success';
  confirmedAt: number | null;
  sentAt: number;
  token: TokenMint;
  quote: TradableQuote | null;
};
export const SwipeContext = createContext<SwipeContextType>({
  addPendingTrade: (signature, token, quote) => {},
  pendingTrades: new Map(),
  addFailedMintToStack: (mint) => {},
  addTokenToHistory: (token) => {},
  swipeTokensStack: []
  // failedMintsStack: [],
  // historyTokensMap: new Map()
});

export const SwipeProvider = ({ children }: { children: ReactNode }) => {
  const { publicKey } = useProxyWallet();

  const { data: trendingTokens } = useQuery({
    queryKey: ['trendingTokens'],
    queryFn: () =>
      fetch(`${apiUrl}/v1/tokens/trending`)
        .then((res) => res.json())
        .then((res) => res?.data ?? []),
    enabled: publicKey !== null,
    refetchInterval: 10000
  });

  const [swipeTokensStack, setSwipeTokensStack] = useState<TokenMint[]>([]);

  const [failedMintStack, setFailedTokensMintStack] = useState<string[]>([]);
  const [historyTokensMap, setHistoryTokensMap] = useState(new Map<string, TokenMint>());
  const [cachedTrendingTokens, setCachedTrendingTokens] = useState<TokenMint[]>([]);
  const [pendingTrades, setPendingTrades] = useState(
    new Map<string, PendingTrade>([
      // [
      //   'signature1',
      //   {
      //     signature: 'signature1',
      //     status: 'success',
      //     confirmedAt: Date.now(),
      //     sentAt: Date.now(),
      //     token: {
      //       mint: 'mint1',
      //       symbol: '$mint1'
      //     } as TradableToken,
      //     quote: {
      //       inMint: 'token',
      //       outMint: 'sol',
      //       tokenAmount: '1230000000', //123M
      //       tokenDecimals: 1,
      //       solanaAmount: null
      //     } as TradableQuote
      //   } as PendingTrade
      // ],
      // [
      //   'signature2',
      //   {
      //     signature: 'signature2',
      //     status: 'success',
      //     confirmedAt: Date.now(),
      //     sentAt: Date.now(),
      //     token: {
      //       mint: 'mint2',
      //       symbol: '$mint2'
      //     } as TradableToken,
      //     quote: {
      //       inMint: 'token',
      //       outMint: 'sol',
      //       tokenAmount: '50000', //5k
      //       tokenDecimals: 1,
      //       solanaAmount: null
      //     } as TradableQuote
      //   } as PendingTrade
      // ],
      //
      // [
      //   'signature3',
      //   {
      //     signature: 'signature3',
      //     status: 'failed',
      //     confirmedAt: Date.now(),
      //     sentAt: Date.now(),
      //     token: {
      //       mint: 'mint2',
      //       symbol: '$mint2'
      //     } as TradableToken,
      //     quote: null
      //   } as PendingTrade
      // ],
      // [
      //   'signature4',
      //   {
      //     signature: 'signature4',
      //     status: 'pending',
      //     confirmedAt: Date.now(),
      //     sentAt: Date.now(),
      //     token: {
      //       mint: 'mint4',
      //       symbol: '$mint4'
      //     } as TradableToken,
      //     quote: null
      //   } as PendingTrade
      // ]
    ])
  );

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const signatures = [...pendingTrades.entries()]
        .filter(([key, value]) => value.status === 'pending')
        .map(([key, value]) => key);

      if (signatures.length > 0) {
        const result = await fetch(rpcUrl, {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getSignatureStatuses',
            params: [
              signatures,
              {
                searchTransactionHistory: true
              }
            ]
          })
        });
        const data = await result.json();

        for (let i = 0; i < signatures.length; i++) {
          const signature = signatures[i];
          const result = data?.result?.value[i];
          const pending = pendingTrades.get(signature);
          if (!pending) continue;

          if (pending.status === 'pending') {
            if (result) {
              const status =
                result.err === null && result.status.Ok !== undefined ? 'success' : 'failed';

              setPendingTrades(
                (map) =>
                  new Map(
                    map.set(signature, {
                      ...pending,
                      confirmedAt: Date.now(),
                      status
                    })
                  )
              );

              if (status === 'failed') {
                addFailedMintToStack(pending.token.mint);
              }
            }

            if (!result && pending.sentAt < Date.now() - 1000 * 20) {
              setPendingTrades(
                (map) =>
                  new Map(
                    map.set(signature, {
                      ...pending,
                      confirmedAt: Date.now(),
                      status: 'failed'
                    })
                  )
              );

              addFailedMintToStack(pending.token.mint);
            }
          }
        }
      }

      for (const [signature, pending] of pendingTrades) {
        if (pending.confirmedAt && pending.confirmedAt < Date.now() - 1000 * 10) {
          setPendingTrades((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.delete(signature);
            return newMap;
          });
        }
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [pendingTrades, setPendingTrades]);

  const addPendingTrade = useCallback(
    (signature: string, token: TokenMint, quote: TradableQuote | null) => {
      setPendingTrades(
        (map) =>
          new Map(
            map.set(signature, {
              status: 'pending',
              confirmedAt: null,
              sentAt: Date.now(),
              signature,
              token,
              quote
            })
          )
      );
    },
    [setPendingTrades]
  );

  useEffect(() => {
    if (!trendingTokens) return;

    const oldHash = hash(JSON.stringify(cachedTrendingTokens));
    const newHash = hash(JSON.stringify(trendingTokens));

    if (trendingTokens.length > 0 && oldHash !== newHash) {
      setCachedTrendingTokens(trendingTokens);
    }
  }, [trendingTokens, failedMintStack, setCachedTrendingTokens]);

  const addFailedMintToStack = useCallback(
    (mint: string) => {
      setFailedTokensMintStack((current) => {
        return [...failedMintStack, mint];
      });

      const token = historyTokensMap.get(mint);

      if (token) {
        setTimeout(() => {
          setSwipeTokensStack((current) => {
            return [current[0], token, ...current.slice(1)].slice(0, STACK_SIZE);
          });
        }, 1000);
      }
    },
    [historyTokensMap, setFailedTokensMintStack, setSwipeTokensStack]
  );

  const addTokenToHistory = useCallback(
    (token: TokenMint) => {
      setHistoryTokensMap((map) => new Map(map.set(token.mint, token)));
      setSwipeTokensStack((current) => {
        return current.filter((t) => t.mint !== token.mint).slice(0, STACK_SIZE);
      });
    },
    [setHistoryTokensMap, setSwipeTokensStack]
  );

  useEffect(() => {
    const cleanCached = cachedTrendingTokens.filter(
      (token) =>
        !historyTokensMap.has(token.mint) && !swipeTokensStack.find((t) => t.mint === token.mint)
    );

    for (const t of cleanCached) {
      if (historyTokensMap.has(t.mint)) {
      }
    }

    if (swipeTokensStack.length < STACK_SIZE) {
      const missing = STACK_SIZE - swipeTokensStack.length;

      if (missing > 0) {
        setSwipeTokensStack((current) => {
          return [...current, ...cleanCached.slice(0, missing)].slice(0, STACK_SIZE);
        });
      }
    }
  }, [cachedTrendingTokens, historyTokensMap, failedMintStack]);

  return (
    <SwipeContext.Provider
      value={{
        addFailedMintToStack,
        addTokenToHistory,
        swipeTokensStack,
        pendingTrades,
        addPendingTrade
      }}>
      {children}
    </SwipeContext.Provider>
  );
};

export default SwipeProvider;

function hash(string: string) {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
    return hashHex;
  });
}
